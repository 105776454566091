<template>
    <v-container class=" my-10 pa-10" style="border-radius: 8px !important; background-color: #f2f2f2; box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important;">
      <router-view></router-view>

      <v-row
        class="white mx-0 my-1 pa-0 "
        style="box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important; border-radius: 4px !important;"
      >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Criar nova competência
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field label="Competencia"></v-text-field>
              <v-autocomplete label="Farol"></v-autocomplete  >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row
        class="white ma-0 pa-0 "
        style="box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important; border-radius: 4px !important;"
      >
        <v-col cols="2">
          <v-text-field
            outlined
            label="Competência"
            v-model="competencia"
            placeholder="dd/yyyy"
            v-mask="'##/####'" 
            style="font-family: Roboto, sans-serif !important;"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            outlined
            label="Farol"
            v-model="farol_res"
            :items="farol" 
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            :items="lista_1_array"
            outlined
            label="Colaborador"
            v-model="medico"
            @change="load_medico()"
            return-object
            clearable

          >
          </v-autocomplete>
        </v-col>
        <v-col 
          cols="2"
        >
          <v-btn 
            class="my-1 py-6"
            outlined 
            block
            @click="load_medico()"
            id="btn_search"
          >PESQUISAR <v-icon class="ma-2">mdi-magnify</v-icon></v-btn>
          
        </v-col>
      </v-row>
      <v-data-table
          dense
          :headers="headers"
          :items="indicadores"
          item-key="name"
          class="elevation-1"
          :loading="loading"
          :search="pesquisa"
          :items-per-page="20"
          style="box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important; font-family: Roboto, sans-serif !important;"
      >
        <template v-slot:top>
          <v-text-field
            v-model="pesquisa"
            label="Filtro"
            class="mx-4"
          ></v-text-field>
        </template>

        <template 
            v-slot:[`item.valor`]="{ item }">
          <v-text-field
            v-model="item.valor"     
          >
            <template v-slot:append>
              <v-btn @click="update(item)" icon><v-icon>mdi-content-save</v-icon></v-btn>
            </template>
          </v-text-field>

        </template>
        
      </v-data-table>


  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import api from '@/http'

  export default {
    name: 'Lista',
    components:{
     //Indicador:()=>import('./Indicador.vue')
    },
    data: () => ({
      pesquisa:'',
      farol_res:'',
      loading:false,
      farol:[],
      competencia: new Date().toLocaleDateString().substr(3),
      headers: [
          {text: 'Cod', align: 'start', sortable: true, value: 'id'},
          {text: 'Competência', align: 'start', sortable: true, value: 'competencia'},
          {text: 'Pilar', align: 'start', sortable: true, value: 'pilar'},
          {text: 'Periodicidade', align: 'start', sortable: true, value: 'periodicidade'},
          {text: 'Indicador', align: 'start', sortable: true, value: 'indicador'},
          {text: 'Orientação', align: 'start', sortable: true, value: 'orientacao'},
          {text: 'Meta indicador', align: 'start', sortable: true, value: 'meta'},
          {text: 'Peso indicador', align: 'start', sortable: true, value: 'peso'},
          {text: 'Valor indicador', width:'120', align: 'end', sortable: true, value: 'valor'},
          // {text: 'Realizado %', width:'120', align: 'end', sortable: true, value: 'realizado'},
          {text: 'Result indicador', width:'120', align: 'end', sortable: true, value: 'resultado'},
          //{text: '%', width:'120', align: 'end', sortable: true, value: 'realizado_pilar'},
          {text: 'Peso Pilar', align: 'start', sortable: true, value: 'indicador_peso'},
          {text: 'Result Pilar', width:'120', align: 'end', sortable: true, value: 'resultado_pilar'},
          {text: 'Score médico', align: 'start', sortable: true, value: 'score'},
      ],
      medico:{id:0},
      desserts: [
      
      ],
    }),
    computed: {
      ...mapGetters(['lista_1_array','lista_6']),
      indicadores(){
        let arr = []
        if (this.lista_6.length>0){
          return this.lista_6
        }
        return arr
      }
    },
    methods:{
      get_farol(){
        return new Promise(()=>{
          api({
            url: "/farol/lista/",
            method: 'get',
          }).then(res=>{

            console.log(res.data)
            this.farol = res.data
          })
        })
        
      },
      
      update(val){
        this.$store.dispatch('lista',{
          url:`/farol/performance/${val.id}/`,
          data:{valor:val.valor,competencia:this.competencia},
          method:'put',
          instancia:'lista_6'
        })
        this.load_medico()
      },
      async load_medico(){
        this.loading = true
        try{
          
          setTimeout(()=>{
            this.$store.dispatch('lista',{
              url:`/farol/performance-lista/?colaborador=${this.medico.id}&competencia=${this.competencia}`,
              method:'get',
              instancia:"lista_6"
            })
            this.loading = false
          },1500)
        }catch(e){
          console.log(e.data)
        }
      }
    
    },
    mounted(){
      
      this.get_farol()
      console.log(this.farol)
      this.$store.dispatch("lista", {
          url: "/etl/get-prestador/",
          method: 'get',
          instancia:'lista_1_array'
      })
    }
  }
</script>
<style>
  #btn_search{
    box-shadow: 2px 2px 5px 1px  rgba(0, 0, 0, .1);
    height: 55px !important;  
  }
  #btn_search:hover{
    background-color: rgba(41, 227, 193 ,.3)!important;
    transition: all ease-in-out .4s !important;
    color: #222 !important;
  }
</style>